<template>
  <div>
    <div class="mobile_header text_white py-4 px-md-5 px-4">
      <div class="row m-0 justify-content-between">
        <div class="col-3 p-0">
        </div>
        <div class="col-9 p-0 text-right clickable" @click="$emit('toggleMenu', !showMenu)" >
          <span class="material-icons menu_icon" v-if="showMenu">
            close
          </span>
          <span class="material-icons menu_icon" v-else>
            menu
          </span>
        </div>
      </div>
      <div class="row m-0 justify-content-between mt-2">
        <div class="col-4 p-0">
          <!-- <img src="@/assets/images/avatar.png" class="abs_img"> -->
          <profile-image  class="abs_img"/>
        </div>
        <div class="col-md-5 col-6 p-0 text-right font4p color_beige">
          {{profile.namePreferred || profile.name}}
        </div>
      </div>
      <div>
      </div>
    </div>
    <!-- <div class="mobile_menu py-4 px-md-5 px-4 overflow_y" v-if="isMenu">
      <custom-menu></custom-menu>
    </div> -->
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'MobileHeader',
  props: ['theme', 'showMenu', 'activePage', 'hasSubmenu', 'isSubmenu'],
  components: {
    'profile-image': () => import('@/components/ProfileImage.vue')
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'profile',
      'serverMedia'
    ])
  },
  methods: {
  }
}
</script>
<style scoped>
.menu_icon {
  /* color: var(--sand_t); */
  color: var(--beige085);
}
.mobile_header {
  height: 120px;
  background-color: var(--blue-black);
  z-index: 11;
}
.mobile_menu {
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  /* background-color: rgba(88, 89, 91, 0.85); */
  background-color: var(--blue-black);
  z-index: 12;
  color: #CCE2E5;
}
.abs_img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100px;
  width: 100px;
  z-index: 12;
}
@media (min-width: 768px) {
  .mobile_header {
    height: 170px;
  }
  .abs_img {
    height: 190px;
    width: 190px;
  }
}
</style>
